import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Metcon Week`}</em></p>
    <p><strong parentName="p">{`Dallas 5`}</strong></p>
    <p>{`5 minutes of:\\
Burpees\\
Then, 5 minutes of:\\
7 deadlifts, 155 lb./105 lb.\\
7 box jumps, 24-in./20-in. box\\
Then, 5 minutes of:\\
Turkish get-ups, 40-lb./30-lb dumbbell\\
Then, 5 minutes of:\\
7 snatches, 75 lb./55 lb.\\
7 push-ups\\
Then, 5 minutes of:\\
Rowing (calories)`}</p>
    <p>{`Complete as many reps as possible at each 5-minute station. Rest 1
minute between stations.`}</p>
    <p>{`Post reps for each station to comments.`}</p>
    <hr></hr>
    <p>{`On July 7, 2016, a sniper coordinated an ambush on a group of police
officers in Dallas, Texas. Dallas 5 commemorates the five officers who
lost their lives in the attack.`}</p>
    <p>{`Dallas Police Officer Patricio “Patrick” Zamarripa, 33, was a member of
the force for six years and served active duty with the U.S. Navy for
eight years and in the reserves for five. He is survived by his wife,
Kristy, and daughter, Lyncoln Rae.`}</p>
    <p>{`Dallas Police Senior Corporal Lorne Ahrens, 48, was a longtime member of
the force. He served with the Los Angeles Police Department for 10 years
before moving to Texas and joining the Dallas Police Department in 2002.
He is survived by his wife, Katrina, and children, Sorcha and Magnus.`}</p>
    <p>{`Dallas Police Officer Michael Krol, 40, was an eight-year veteran of the
Dallas Police Department and a dedicated member of the Wayne County
Sheriff’s Office in Detroit, Michigan, before that. He is survived by
numerous friends and family members.`}</p>
    <p>{`Dallas Police Sergeant Michael Smith, 55, served as a U.S. Army Ranger
before joining the police force in 1989. The 27-year veteran of the
force is survived by his wife, Heidi, and daughters, Victoria and
Caroline.`}</p>
    <p>{`Dallas Area Rapid Transit Officer Brent Thompson, 43, served in the
Marine Corps before joining the Corsicana Police Department and then the
Dallas Police. He is survived by his wife, Emily, and many other friends
and family members.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      